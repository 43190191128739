



























import { Component, Vue, Prop } from 'vue-property-decorator';

import { Instruction as IInstruction } from './interface/IInstruction';

@Component({})
export default class Instruction extends Vue {
  @Prop() listInstruction!: Array<IInstruction>;
}
