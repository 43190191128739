const LIST_INSTRUCTIONS = [
  {
    text:
      'O simulado a seguir é composto da mesma quantidade de questões do vestibular selecionado;'
  },
  {
    text: 'Só pode realizar o simulado uma única vez;'
  },
  {
    text: 'O tempo para realização do simulado é o mesmo do dia do vestibular selecionado;'
  },
  {
    text: 'Tenha lápis e papel à mão;'
  },
  {
    text:
      'Diferente das redações avulsas da nossa plataforma, a redação do simulado só poderá ser enviada uma vez. Então capriche, tá?'
  }
];

export default LIST_INSTRUCTIONS;
