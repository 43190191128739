const OPTIONS_LANGUAGES = [
  {
    id: 13,
    value: 13,
    title: 'Espanhol'
  },
  {
    id: 3,
    value: 3,
    title: 'Inglês'
  }
];

export default OPTIONS_LANGUAGES;
