





































import { Component, Vue } from 'vue-property-decorator';

import { compareAsc } from 'date-fns';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import Instruction from '@/components/Instruction/Instruction.vue';
import InstructionLoading from '@/components/Instruction/InstructionLoading.vue';
import Footer from '@/components/Footer/Footer.vue';

import ContainerButtonsInstruction from './components/ContainerButtonsInstruction/index.vue';
import ContainerButtonsInstructionLoading from './components/ContainerButtonsInstruction/ContainerButtonsInstructionLoading.vue';
import ModalSelectLanguage from './components/Modals/ModalSelectLanguage.vue';

import ConfigSidebar from './mixins/ConfigSidebar';

import ActiveModal from '@/share/Util/ActiveModal';

import LIST_INSTRUCTIONS from './constants/ListInstructionSimulated';

import SimulationService from '@/services/Simulation/SimulationService';

import { SimulatedDay } from './interface/ISimulated';

const PERMISSION_SAVE_GET_DATA_STORE = true;
const INDEX_SIMULATED_FIRST_DAY = 0;
const INDEX_SIMULATED_SECOND_DAY = 1;
const MSG_ERROR_SIMULATED = 'Erro ao carregar os dados do simulado. Tente novamente em instantes.';

@Component({
  components: {
    PageHeader,
    ContainerFluid,
    BoxContainer,
    Instruction,
    InstructionLoading,
    ModalSelectLanguage,
    ContainerButtonsInstruction,
    ContainerButtonsInstructionLoading,
    Footer
  },
  mixins: [ConfigSidebar]
})
export default class SimulatedQuestion extends Vue {
  private listInstruction = LIST_INSTRUCTIONS;

  private activeModal = new ActiveModal();
  private infoSimulated: Array<SimulatedDay> = [];

  private ServiceSimulation = new SimulationService();

  created() {
    this.getSimulatedQuestions();
  }

  mounted() {
    this.setBreadCrumbs();
  }

  beforeDestroy() {
    this.$store.commit('setSidebar', false);
    this.$store.commit('setSidebarExistence', false);
    this.$store.commit('setRedactionSubmissionSettings', {
      component: '',
      time: null
    });
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get titleSimulated() {
    return this.$store.getters.simulatedTitleCover.title || '';
  }

  get isLoadingQuestionsSimulated() {
    return this.$store.getters.isLoadingQuestionsSimulated;
  }

  get dateFirstDay() {
    return this.infoSimulated[INDEX_SIMULATED_FIRST_DAY]?.init_date;
  }

  get dateSecondDay() {
    return this.infoSimulated[INDEX_SIMULATED_SECOND_DAY]?.init_date;
  }

  get isShowButton() {
    const currentDate = new Date();

    const isValidDateSimulatedFistDay = compareAsc(currentDate, new Date(this.dateFirstDay)) >= 0;
    const isValidDateSimulatedSecondDay = compareAsc(currentDate, new Date(this.dateSecondDay)) >= 0;

    return isValidDateSimulatedFistDay || isValidDateSimulatedSecondDay;
  }

  showModalSelectLanguage() {
    this.activeModal.activeModal('ModalSelectLanguage');
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: `Simulado${this.titleSimulated ? 's' : ''}`, to: '/' },
      { title: this.titleSimulated, to: null }
    ]);
  }

  async getSimulatedQuestions() {
    try {
      const { id } = this.$route.params;

      const response = await this.ServiceSimulation.getSimulated(
        Number(id),
        PERMISSION_SAVE_GET_DATA_STORE
      );

      if (response) this.infoSimulated = response.results;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: MSG_ERROR_SIMULATED,
        status: 'error'
      });
    }
  }
}
